// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (window.matchMedia("(min-width:769px)").matches && device === "pc") {
    return true;
  }
  return false;
}
// FUNCTIONS
function topPcFunc() {
  // This code loads the IFrame Player API code asynchronously.
  var tag = document.createElement("script");

  var firstText = document.getElementById("firstText");
  // var secoundText = document.getElementById("secoundText");
  var sliderWrap = document.getElementById("sliderWrap");
  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");

  var loadingWrap = document.getElementById("loadingWrap");
  // 画像用配列
  var images = ["../img/bg-sara.jpg", "../img/bg-gra01.jpg", "../img/bg-gra02.jpg"];

  // 画像プリロード

  for (i = 0; i < images.length; i++) {
    var img = document.createElement("img");
    img.src = images[i];
    // console.log(i + "と" + images.length);

    if (i === images.length - 1) {
      // console.log("finish");
      initTop();
    }
  }
  function initTop() {
    console.log("in it");
    loadingWrap.classList.add("show");

    setTimeout(function() {
      loadingWrap.classList.add("hide");
      headerWrap.classList.add("show");
      firstWrap.classList.add("show");
      firstText.classList.add("show");
      // secoundText.classList.add("show");
    }, 1000);
  }
}

function topSpFunc() {
  // This code loads the IFrame Player API code asynchronously.
  var tag = document.createElement("script");

  var firstText = document.getElementById("firstText");
  // var secoundText = document.getElementById("secoundText");
  var sliderWrap = document.getElementById("sliderWrap");
  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");

  var loadingWrap = document.getElementById("loadingWrap");
  // 画像用配列
  var images = ["../img/bg-sara.jpg", "../img/bg-gra01.jpg", "../img/bg-gra02.jpg"];

  // 画像プリロード

  for (i = 0; i < images.length; i++) {
    var img = document.createElement("img");
    img.src = images[i];
    // console.log(i + "と" + images.length);

    if (i === images.length - 1) {
      // console.log("finish");
      initTop();
    }
  }
  function initTop() {
    console.log("in it");
    loadingWrap.classList.add("show");

    setTimeout(function() {
      loadingWrap.classList.add("hide");
      headerWrap.classList.add("show");
      firstWrap.classList.add("show");
      firstText.classList.add("show");
      // secoundText.classList.add("show");
    }, 1000);
  }
}

function subFunc() {
  var headerWrap = document.getElementById("headerWrap");
  headerWrap.classList.add("show");
}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {
  $(".sub-page .head-line").addClass("show");
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");

  var observer = lozad(".lozad", {
    threshold: [0, 1.0],
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add("loaded");
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});

  if ($("#topPage").length) {
    var swiper = new Swiper("#galleryTop", {
      effect: "fade",
      speed: 3000,
      autoplay: {
        delay: 3000
      },
      loop: true
    });

    if (mq("sp")) {
      topSpFunc();
    } else {
      topPcFunc();
    }
  } else {
    subFunc();
  }

  // SMOOTH SCROOL FOR CLASS NAME
  $("a.scroll").click(function() {
    console.log("TEST");
    $("html,body").animate({ scrollTop: $($(this).attr("href")).offset().top - 80 }, "slow", "swing");
    $("body").removeClass("menu-show");

    return false;
  });

  // SMOOTH SCROOL ATTRIBUTE NAME
  // $('a[href^="#"]').click(function() {
  //   var speed = 500;
  //   var href = $(this).attr("href");
  //   var target = $(href == "#" || href == "" ? "html" : href);
  //   var position = target.offset().top;
  //   $("html, body").animate({ scrollTop: position }, speed, "swing");
  //   return false;
  // });
  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });

  $("#closeBtn").click(function() {
    $(".youtube-wrap").removeClass("show");
  });
  $("#youtubeBtn").click(function() {
    $(".youtube-wrap").addClass("show");
    return false;
  });

  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
  } else {
    console.log("Desktop");
  }
});

// SCROLL AND TOCH START ANIMATION
// $(window).on('touchstart scroll', function() {
//   var myClass = $(
//     '.head-line, #topPage .bussiness-list li, #topPage .vision-list li, #topPage .about-list li'
//   );
//   var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
//   var windowHeight = document.documentElement.clientHeight;
//   for (var i = 0; i < myClass.length; i++) {
//     var rect = myClass[i].getBoundingClientRect();
//     var imgPos = rect.top + scrollTop + 300;

//     if (scrollTop > imgPos - windowHeight) {
//       myClass[i].classList.add('show');
//     } else if (scrollTop < imgPos + windowHeight) {
//       // myClass[i].classList.remove('show');
//     }
//   }
//   if (scrollTop > 150) {
//     document.getElementById('headerWrap').classList.add('fixed');
//     document.getElementById('pageTop').classList.add('show');
//   } else if (scrollTop < 150) {
//     document.getElementById('headerWrap').classList.remove('fixed');
//     document.getElementById('pageTop').classList.remove('show');
//   }
// });
